// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var box__liLX = "PlasmicFeatures-module--box__liLX--LWOgj";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var box__vytie = "PlasmicFeatures-module--box__vytie--3QncK";
export var box__a7Ri6 = "PlasmicFeatures-module--box__a7Ri6--1yu7K";
export var box__cd79W = "PlasmicFeatures-module--box__cd79W--ekQpG";
export var box__hwIzq = "PlasmicFeatures-module--box__hwIzq--3B-uN";
export var bullet__jHAzm = "PlasmicFeatures-module--bullet__jHAzm--SbE6S";
export var bullet__lCwR = "PlasmicFeatures-module--bullet__lCwR--3-DVJ";
export var bullet__yPmeq = "PlasmicFeatures-module--bullet__yPmeq--GrjbP";
export var box__pvsqM = "PlasmicFeatures-module--box__pvsqM--9uWDq";
export var box__aqtmd = "PlasmicFeatures-module--box__aqtmd--1H7KO";
export var box___6P5P3 = "PlasmicFeatures-module--box___6P5P3--17dKp";
export var img___7VEvU = "PlasmicFeatures-module--img___7VEvU--3k6L0";
export var img__gBat1 = "PlasmicFeatures-module--img__gBat1--1Ol6C";
export var box__vfeyY = "PlasmicFeatures-module--box__vfeyY--1x8IL";
export var box__hIPr = "PlasmicFeatures-module--box__hIPr--XNIHP";
export var img__qBa9H = "PlasmicFeatures-module--img__qBa9H--1yNPk";
export var img__wIKy = "PlasmicFeatures-module--img__wIKy--2DfGU";
export var box__emk4A = "PlasmicFeatures-module--box__emk4A--3RRrW";
export var box__rjUuL = "PlasmicFeatures-module--box__rjUuL--28n-h";
export var box___2Z7Z = "PlasmicFeatures-module--box___2Z7Z--PUYco";
export var bullet___0PhOq = "PlasmicFeatures-module--bullet___0PhOq--n9PBo";
export var bullet__muh5B = "PlasmicFeatures-module--bullet__muh5B--16pT8";
export var bullet__beu5 = "PlasmicFeatures-module--bullet__beu5--2-OeE";
export var box__fXqpx = "PlasmicFeatures-module--box__fXqpx--3KFWo";
export var box___3MhGz = "PlasmicFeatures-module--box___3MhGz--1E-89";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";