// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box__yFgV = "PlasmicPlan-module--box__yFgV--20YnV";
export var box__nswwm = "PlasmicPlan-module--box__nswwm--_S7Eb";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box__oInPf = "PlasmicPlan-module--box__oInPf--3Ak-B";
export var box__ucaSw = "PlasmicPlan-module--box__ucaSw--2nejr";
export var bullet__pzBfs = "PlasmicPlan-module--bullet__pzBfs--86Gsw";
export var bullet__mrUrd = "PlasmicPlan-module--bullet__mrUrd--Fqq0M";
export var bullet__ubVo0 = "PlasmicPlan-module--bullet__ubVo0--3Hd0R";
export var box__mG4F = "PlasmicPlan-module--box__mG4F--3umPj";
export var svg__mQqBo = "PlasmicPlan-module--svg__mQqBo--12L0b";