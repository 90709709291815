// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box__l2Rnk = "PlasmicTopSection-module--box__l2Rnk--3ND7M";
export var box__ebmkD = "PlasmicTopSection-module--box__ebmkD--9MKPZ";
export var box__zILlV = "PlasmicTopSection-module--box__zILlV--ibIQx";
export var box___90Tjy = "PlasmicTopSection-module--box___90Tjy--3hN8X";
export var box__vuoc = "PlasmicTopSection-module--box__vuoc--hVpwX";
export var box__hqx2Z = "PlasmicTopSection-module--box__hqx2Z--7-820";
export var box__vmJy1 = "PlasmicTopSection-module--box__vmJy1--1-EkR";
export var linkButton__ecrPw = "PlasmicTopSection-module--linkButton__ecrPw--3hcni";
export var linkButton__nxkk7 = "PlasmicTopSection-module--linkButton__nxkk7--ioCJ6";
export var box__oOtm = "PlasmicTopSection-module--box__oOtm--uSUyi";
export var img = "PlasmicTopSection-module--img--TX3Qd";