// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box__wrXqq = "PlasmicFeatureCard-module--box__wrXqq--1M0pS";
export var box__q4BlD = "PlasmicFeatureCard-module--box__q4BlD--2wagE";
export var svg__zFjN7 = "PlasmicFeatureCard-module--svg__zFjN7--n-JrA";
export var box__y0DAt = "PlasmicFeatureCard-module--box__y0DAt--2eYUV";
export var box__long__y0DATfYhC = "PlasmicFeatureCard-module--box__long__y0DATfYhC--1P_Dn";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box__k2VH = "PlasmicFeatureCard-module--box__k2VH--1Qt3i";
export var box__long__k2VHfYhC = "PlasmicFeatureCard-module--box__long__k2VHfYhC--2kSiV";
export var svg__nsbQa = "PlasmicFeatureCard-module--svg__nsbQa--E8RUK";