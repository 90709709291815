// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box__btPu = "PlasmicFooter-module--box__btPu--3Eltc";
export var box__kz23X = "PlasmicFooter-module--box__kz23X--qxOB8";
export var box__oFtjX = "PlasmicFooter-module--box__oFtjX--3-_Xi";
export var iconLink__lz04E = "PlasmicFooter-module--iconLink__lz04E--270Kq";
export var svg___8QjEw = "PlasmicFooter-module--svg___8QjEw--i_Qj0";
export var box__ezoR = "PlasmicFooter-module--box__ezoR--UlPho";
export var link__v6Mst = "PlasmicFooter-module--link__v6Mst--XxmGc";
export var box__i5Oso = "PlasmicFooter-module--box__i5Oso--2ouFK";
export var link__nVbXz = "PlasmicFooter-module--link__nVbXz--1CGeo";
export var box__ub9Xc = "PlasmicFooter-module--box__ub9Xc--crj7O";
export var box__pCctD = "PlasmicFooter-module--box__pCctD--2z4xu";
export var box__vvt6D = "PlasmicFooter-module--box__vvt6D--3vtkF";
export var link___6VxVs = "PlasmicFooter-module--link___6VxVs--ewktZ";
export var link__r0Z8 = "PlasmicFooter-module--link__r0Z8--MDCNz";
export var link__a0Snk = "PlasmicFooter-module--link__a0Snk--3WDGK";
export var link__gDdHs = "PlasmicFooter-module--link__gDdHs--_WJU-";
export var link___1J3Y = "PlasmicFooter-module--link___1J3Y--1YV_T";
export var box__w3Mwh = "PlasmicFooter-module--box__w3Mwh--1akF8";
export var box___3EwZr = "PlasmicFooter-module--box___3EwZr--1RXvd";
export var link___8KvJ5 = "PlasmicFooter-module--link___8KvJ5--1Bsde";
export var link__xdCy = "PlasmicFooter-module--link__xdCy--1zkmS";
export var link__s9Swt = "PlasmicFooter-module--link__s9Swt--3hJsA";
export var link__coTeb = "PlasmicFooter-module--link__coTeb--3L2E3";
export var link__wX6Wt = "PlasmicFooter-module--link__wX6Wt--2oG_n";
export var box__iPmmY = "PlasmicFooter-module--box__iPmmY--1n7aT";
export var box__tzuz = "PlasmicFooter-module--box__tzuz--3cPzO";
export var link__xCj7 = "PlasmicFooter-module--link__xCj7--31MtX";
export var link__arDgm = "PlasmicFooter-module--link__arDgm--1FwjT";
export var link__iRlo9 = "PlasmicFooter-module--link__iRlo9--GoYnp";
export var link___4YTGt = "PlasmicFooter-module--link___4YTGt--1J0NZ";
export var link__yh1L4 = "PlasmicFooter-module--link__yh1L4--2eGDw";
export var box__vtHsr = "PlasmicFooter-module--box__vtHsr--3DVFF";
export var box__xoEmg = "PlasmicFooter-module--box__xoEmg--BFV0s";
export var box__lgY0N = "PlasmicFooter-module--box__lgY0N--29nEP";
export var box__grg4Z = "PlasmicFooter-module--box__grg4Z--TdiP2";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var box__q4U2 = "PlasmicFooter-module--box__q4U2--3cj7b";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var svg__nT8AX = "PlasmicFooter-module--svg__nT8AX--fXerD";
export var box__cjuHy = "PlasmicFooter-module--box__cjuHy--1fS44";
export var box___17Kd7 = "PlasmicFooter-module--box___17Kd7--rqlWq";
export var box__quams = "PlasmicFooter-module--box__quams--12_-n";
export var link___8ZSQq = "PlasmicFooter-module--link___8ZSQq--1ac_8";
export var link__oQhw = "PlasmicFooter-module--link__oQhw--1zv8u";
export var box__zjCHb = "PlasmicFooter-module--box__zjCHb--1Rc51";
export var box__s4WXq = "PlasmicFooter-module--box__s4WXq--1b7ay";
export var iconLink___0LOD = "PlasmicFooter-module--iconLink___0LOD--udM1y";
export var svg__rdZ6D = "PlasmicFooter-module--svg__rdZ6D--3EqO7";
export var iconLink__bnkEt = "PlasmicFooter-module--iconLink__bnkEt--2aomt";
export var svg___7A8Jm = "PlasmicFooter-module--svg___7A8Jm--1h1o_";
export var iconLink__yuSF = "PlasmicFooter-module--iconLink__yuSF--6iNRC";
export var svg__w2NuE = "PlasmicFooter-module--svg__w2NuE--3Lc6n";