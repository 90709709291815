// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box__oR3Fs = "PlasmicPricing-module--box__oR3Fs--1qTQm";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__cjshd = "PlasmicPricing-module--section__cjshd--2u5Rn";
export var box__xyI8P = "PlasmicPricing-module--box__xyI8P--YRG0x";
export var plan__ubc7B = "PlasmicPricing-module--plan__ubc7B--1lReV";
export var box__hUuMr = "PlasmicPricing-module--box__hUuMr--2xKhy";
export var box__vXcpg = "PlasmicPricing-module--box__vXcpg--1-nHQ";
export var bullet__gSwsx = "PlasmicPricing-module--bullet__gSwsx--1nNby";
export var bullet___8Fruv = "PlasmicPricing-module--bullet___8Fruv--2-vmf";
export var bullet___7B9Oa = "PlasmicPricing-module--bullet___7B9Oa--29H7c";
export var plan__mt2Gu = "PlasmicPricing-module--plan__mt2Gu--23EXY";
export var box__xkujK = "PlasmicPricing-module--box__xkujK--3jSPe";
export var box__bfIqX = "PlasmicPricing-module--box__bfIqX--2jzLn";
export var bullet__qxKy = "PlasmicPricing-module--bullet__qxKy--24d80";
export var bullet__du485 = "PlasmicPricing-module--bullet__du485--1S5xH";
export var bullet__oZsjS = "PlasmicPricing-module--bullet__oZsjS--18rP1";
export var bullet__cI4L = "PlasmicPricing-module--bullet__cI4L--3CreF";
export var plan__lAtl = "PlasmicPricing-module--plan__lAtl--1wY3s";
export var box__iqtC0 = "PlasmicPricing-module--box__iqtC0--WBvuX";
export var box__g0Pdr = "PlasmicPricing-module--box__g0Pdr--18cjW";
export var bullet__bAsad = "PlasmicPricing-module--bullet__bAsad--po5DL";
export var bullet__wxir5 = "PlasmicPricing-module--bullet__wxir5--3KdUf";
export var bullet__amSaR = "PlasmicPricing-module--bullet__amSaR--3YWFa";
export var section___2Q9U = "PlasmicPricing-module--section___2Q9U--N83ew";
export var faq__kk7Rx = "PlasmicPricing-module--faq__kk7Rx--1mv-9";
export var faq__uvp9P = "PlasmicPricing-module--faq__uvp9P--2ZEP_";
export var faq__hXcX = "PlasmicPricing-module--faq__hXcX--3GpXt";
export var footer = "PlasmicPricing-module--footer--zhosD";